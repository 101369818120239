import React from "react";
import CookieConsent from "react-cookie-consent";
import PageWrapper from "../components/PageWrapper";
import SEO from "../components/Layout/SEO";

import Hero from "../sections/camelo/Hero";
import Rating from "../sections/camelo/Rating";
import MoreThanSoftware from "../sections/camelo/MoreThanSoftware";
import ComparisonTable from "../sections/camelo/ComparisonTable";
import BottomCTA from "../sections/camelo/BottomCTA";
import SuccessStories from "../sections/camelo/SuccessStories";
import Fact from "../sections/camelo/Fact";
import WhatToExpect from "../sections/camelo/WhatToExpect";
import PeopleAreTalking from "../sections/camelo/PeopleAreTalking";
import ScreenSizes from "../sections/camelo/ScreenSizes";
import Features from "../sections/camelo/Features";
import CTA from "../sections/camelo/features/CTA";

const CameloHome = () => {
  return (
    <>
      <PageWrapper headerConfig={{ theme: "light" }}>
        <SEO title="Camelo: Free Employee Scheduling, Time Clock & Timesheet Software"></SEO>

        <Hero />

        {/* <Rating /> */}

        <Features />

        {/* <SellingPoint1 /> */}
        {/* <SellingPoint2 /> */}
        {/* <SellingPoint3 /> */}

        <ScreenSizes></ScreenSizes>

        {/* <Testimonial /> */}
        <PeopleAreTalking />
        <SuccessStories />
        {/* <Pricing /> */}
        {/* <Faq /> */}
        {/* <ComparisonTable /> */}
        <WhatToExpect />
        <MoreThanSoftware />
        <Fact />
        <CTA />
        <CookieConsent
          containerClasses="bg-white flex-column"
          contentClasses="bg-white"
          contentStyle={{flex: '0 1 auto'}}
          location="bottom"
          buttonText="OK, I understand"
          buttonStyle={{backgroundColor: '#000', color: '#fff'}}
          expires={150}
        >
          <h5 style={{fontSize: '13px', paddingLeft: '16px'}}>Welcome to our website!</h5>
          <div style={{fontSize: '13px', color: 'black', padding: '0px 150px 0 16px'}}>We use cookies to provide you with the best browsing experience, remember your preferences, and show you personalized content. By continuing to use our site, you consent to our use of cookies. You can manage your preferences at any time using your browser settings. Thanks for visiting!</div>
        </CookieConsent>
      </PageWrapper>
    </>
  );
};
export default CameloHome;
