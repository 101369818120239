import React from "react";
import { useEffect, ReactElement } from "react";
import ReactDOMServer from "react-dom/server";

export const PopoverLinkWistiaPlayer = ({
  videoId,
  children,
}: {
  videoId: string;
  children: ReactElement | any;
}) => {
  useEffect(() => {
    const script1 = document.createElement("script");
    script1.src = `https://fast.wistia.com/embed/medias/${videoId}.jsonp`;
    script1.async = true;
    const script2 = document.createElement("script");
    script2.src = "https://fast.wistia.com/assets/external/E-v1.js";
    script2.async = true;
    const div = document.createElement("div");
    div.innerHTML = `<span class="wistia_embed wistia_async_${videoId} popover=true popoverContent=link" style="display:inline;position:relative">${ReactDOMServer.renderToString(
      children,
    )}</span>`;
    const container = document.getElementById(
      `wistia-popoverlink-player-container-${videoId}`,
    );
    if (container) {
      container.appendChild(script1);
      container.appendChild(script2);
      container.appendChild(div);
    }

    return () => {
      if (container) {
        container.innerHTML = "";
      }
    };
  }, [videoId, children]);

  return <div id={`wistia-popoverlink-player-container-${videoId}`} />;
};
