import React, { useRef } from "react";
import { Link } from "react-scroll";
import {
  Container,
  Row,
  Col,
  Button,
  FormControl,
  InputGroup,
} from "react-bootstrap";
import { PopoverLinkWistiaPlayer } from "../../components/Wistia/index";
// import imgHero from "../../assets/image/camelo-phone.svg";
import imgHero from "../../assets/image/free-employee-scheduling-time-clock-app.png";

const Hero = () => {
  const emailInput = useRef(null);

  const handleGetStarted = () => {
    window.open(
      `${process.env.SIGNUP_URL}/?workEmail=${emailInput.current.value}`,
      "__blank",
    );
    if (typeof window.gtag_report_conversion === "function") {
      window.gtag_report_conversion(
        `${process.env.SIGNUP_URL}/?workEmail=${emailInput.current.value}`,
      );
    }
  };

  return (
    <>
      {/* <!-- Hero Area --> */}
      <div className="py-18 position-relative overflow-hidden lg:min-h-vh-100">
        <div
          className="hero-area bg-image  mx-6 mx-lg-9 rounded-15 h-100"
          css={`
            position: relative;
            z-index: 1;
          `}
        >
          <Container>
            <Row className="justify-content-sm-center">
              <Col lg="6" xs="12" className="order-2 order-lg-1">
                <div className="hero-content pr-xl-16 pt-18 pt-lg-31 pb-md-18 pb-lg-30 mb-9 mb-md-0">
                  <h1 className="title gr-text-1 mb-16">
                    Shift work made easier.
                  </h1>
                  <h2
                    className="gr-text-6 mb-0 font-weight-normal"
                    data-aos="fade-up"
                    data-aos-delay="400"
                    data-aos-duration="900"
                  >
                    Camelo is a better way to schedule, connect, and manage your
                    team. <br className="d-none d-lg-block" /> Anywhere.
                    Anytime. On any devices.
                  </h2>
                  <div>
                    <PopoverLinkWistiaPlayer videoId="3326s2hok4">
                      <a
                        className="btn-link text-primary with-icon gr-text-7 font-weight-bold mt-11 d-inline-flex align-items-center"
                        css={`
                          cursor: pointer;
                        `}
                      >
                        Watch demo video{" "}
                        <i className="icon icon-tail-right font-weight-bold"></i>
                      </a>
                    </PopoverLinkWistiaPlayer>
                  </div>
                  <InputGroup
                    data-aos="fade-up"
                    data-aos-delay="1200"
                    data-aos-duration="1000"
                    className="mt-16 get-started-black"
                  >
                    <FormControl
                      placeholder="Your work email"
                      aria-label="Your work email"
                      aria-describedby="basic-addon2"
                      ref={emailInput}
                    />
                    <InputGroup.Append>
                      <Button variant="blue" onClick={handleGetStarted}>
                        Get started
                      </Button>
                    </InputGroup.Append>
                  </InputGroup>
                </div>
              </Col>
              <Col
                lg="5"
                xs="12"
                className="offset-lg-1 align-self-sm-center order-1 order-lg-2"
              >
                <div
                  className="hero-img  z-index-1 mb-md-0"
                  data-aos="fade-left"
                  data-aos-duration="1000"
                  data-aos-delay="500"
                >
                  <img className="w-100" src={imgHero} alt="" />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};

export default Hero;
