import * as React from "react";

function SvgCheckmarkCircle(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.344 24.797c6.715 0 12.246-5.543 12.246-12.258C24.59 5.824 19.047.293 12.344.293 5.629.293.098 5.824.098 12.539c0 6.715 5.53 12.258 12.246 12.258zm-1.301-6.422c-.492 0-.879-.21-1.23-.645L7 14.345c-.234-.305-.34-.586-.34-.903 0-.668.527-1.195 1.195-1.195.387 0 .692.152.997.527l2.156 2.684 4.699-7.5c.281-.457.645-.68 1.055-.68.656 0 1.242.47 1.242 1.137 0 .281-.129.586-.317.867l-5.472 8.426c-.281.434-.703.668-1.172.668z"
        fill={props.fill || "currentColor"}
      />
    </svg>
  );
}

export default SvgCheckmarkCircle;
